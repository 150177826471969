<template>
  <div>
    <MyHeader :title="title" :ShowBack="true" />
    <van-cell-group :title="$t('lang.querycondition')">
      <van-form @submit="onSubmit">
        <van-field name="fDate1" v-model="mDate1" readonly clickable left-icon="calendar-o" :label="$t('lang.startTime')"
          @click="currField = 'fDate1';
          currentDate = new Date(mDate1);
          showDate = true;
                                        " />
        <van-field name="fDate2" v-model=" mDate2 " readonly clickable left-icon="calendar-o"
          :label=" $t('lang.endTime') " @click="
            currField = 'fDate2';
            currentDate = new Date(mDate2);
            showDate = true;
          " />
        <van-field name="fCompany" v-model=" mCompany " readonly clickable left-icon="hotel-o"
          :label=" $t('lang.company') " :placeholder=" $t('lang.selectcompany') " :rules=" [{ required: true }] "
          @click=" onShowSearch('company') " />
        <van-field name="fDept" v-model=" mDept " readonly clickable left-icon="cluster-o"
          :label=" $t('lang.department') " :placeholder=" $t('lang.all') " @click=" onShowSearch('dept') " />
        <van-field name="fLine" v-model=" mLine " readonly clickable left-icon="exchange" :label=" $t('lang.router') "
          :placeholder=" $t('lang.all') " @click=" onShowSearch('line') " />
        <van-field name="fAddr" v-show=" showAddr " v-model=" mAddr " readonly clickable left-icon="location-o"
          :label=" $t('lang.place') " :placeholder=" $t('lang.all') " @click=" onShowSearch('addr') " />
        <van-field name="fGroup" v-show=" showGroup " v-model=" mGroup " readonly clickable left-icon="friends-o"
          :label=" $t('lang.group') " :placeholder=" $t('lang.all') " @click=" onShowSearch('group') " />
        <van-field name="fUser" v-show=" showUser " v-model=" mUser " readonly clickable left-icon="user-o"
          :label=" $t('lang.inspector') " :placeholder=" $t('lang.all') " @click=" onShowSearch('user') " />
        <van-field name="fDevice" v-show=" showDevice " v-model=" mDevice " readonly clickable left-icon="user-o"
          :label=" $t('lang.device') " :placeholder=" $t('lang.all') " @click=" onShowSearch('device') " />

        <van-field name="fState" :label=" $t('lang.inspectionstate') " v-show=" showState " left-icon="flag-o">
          <template #input>
            <van-radio-group v-model=" mState " direction="horizontal">
              <van-radio name="-1">{{ $t("lang.all") }}</van-radio>
              <van-radio name="0">{{ $t("lang.normal") }}</van-radio>
              <van-radio name="1">{{ $t("lang.abnormal") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="fRecord" v-model=" mRecord " v-show=" showRecord " readonly clickable left-icon="user-o"
          :label=" $t('lang.recordtype') " :placeholder=" $t('lang.all') " @click=" onShowSearch('record') " />

        <!--
        <van-field name="fRecord_miss" v-model=" mRecord_miss " v-show=" showRecord_miss " readonly clickable
          left-icon="user-o" :label=" $t('lang.recordtype') " :placeholder=" $t('lang.all') "
          @click=" onShowSearch('record_miss') " />
        -->
        
        <van-field name="fRecord_miss" :label=" $t('lang.recordtype') " v-show=" showRecord_miss " left-icon="flag-o">
          <template #input>
            <van-radio-group v-model=" mRecord_miss " direction="horizontal">
              <van-radio name="0">{{ $t("lang.querytype_miss") }}</van-radio>
              <van-radio name="7">{{ $t("lang.audited") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="fRecord_local" :label=" $t('lang.recordtype') " v-show=" showRecord_local " left-icon="flag-o">
          <template #input>
            <van-radio-group v-model=" mRecord_local " direction="horizontal">
              <van-radio name="1">{{ $t("lang.querytype_user") }}</van-radio>
              <van-radio name="2">{{ $t("lang.querytype_device") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="fTotalType" v-show=" showTotalType " v-model=" mTotalType " readonly clickable
          left-icon="chart-trending-o" :label=" $t('lang.statisticstype') " :placeholder=" $t('lang.selectedplace') "
          @click=" onShowSearch('totaltype') " />

        <van-field v-show=" showKeyword " name="fKeyword" v-model=" mKeyword " left-icon="notes-o"
          :label=" $t('lang.keyword') " :placeholder=" $t('lang.keyword_input') " />

        <van-field name="fOrder" :label=" $t('lang.timeorder') " v-show=" showOrder " left-icon="flag-o">
          <template #input>
            <van-radio-group v-model=" mOrder " direction="horizontal">
              <van-radio name="asc">{{ $t("lang.asc") }}</van-radio>
              <van-radio name="desc">{{ $t("lang.desc") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="fRepairType" :label=" $t('lang.recordtype') " v-show=" showRepairType " left-icon="flag-o">
          <template #input>
            <van-radio-group v-model=" mRepairType " direction="horizontal">
              <van-radio name="-1">{{ $t("lang.all") }}</van-radio>
              <van-radio name="1">{{ $t("lang.repair") }}</van-radio>
              <van-radio name="2">{{ $t("lang.abnormal1") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button block color="#669999" native-type="submit" @click=" bt_event = 'query' " icon="search">
            {{ $t("lang.query") }}
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button v-show=" showExport " block color="#669999" native-type="submit" @click=" bt_event = 'export' "
            icon="down">
            {{ $t("lang.export") }}
          </van-button>
        </div>
      </van-form>
    </van-cell-group>

    <!--日历-->
    <van-popup v-model=" showDate " :style=" { width: '100%' } ">
      <van-datetime-picker v-model=" currentDate " type="datetime" :title=" $t('lang.selecttime') " :min-date=" minDate "
        :max-date=" maxDate " :formatter=" formatter " @cancel=" showDate = false " @confirm=" onConfirmTime " />
    </van-popup>

    <!--通用搜索-->
    <van-popup v-model=" showSearch " position="bottom" :style=" { height: '75%' } ">
      <form action="/">
        <van-search v-model=" mKeyword1 " show-action :placeholder=" $t('lang.keyword_input') " @input=" onSearch "
          @cancel=" showSearch = false " />
      </form>
      <van-cell-group>
        <van-cell :title=" $t('lang.all') " clickable @click=" onSelected('-1', $t('lang.all')) "
          v-show=" showAllCell " />
        <van-cell v-for="   mData    in    lstDataFilter   " :key=" mData.id " :title=" mData.name " clickable
          @click=" onSelected(mData.id, mData.name) " />
      </van-cell-group>
    </van-popup>
  </div>
</template>

<style scoped>
.success-icon {
  color: red;
}
</style>
<script>
import MyHeader from "@/components/Header.vue";
import {setSessionStr,getSessionStr,setSessionObj} from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      title: "",
      bt_event: "query",
      showExport: false,

      lstCompany: [],
      lstDept: [],
      lstLine: [],
      lstAddr: [],
      lstGroup: [],
      lstUser: [],
      lstDevice:[],
      lstRecord: [
        { name: this.$t("lang.querytype_miss"), id: 0 },
        { name: this.$t("lang.querytype_qualified"), id: 1 },
        { name: this.$t("lang.querytype_onTime"), id: 2 },
        { name: this.$t("lang.querytype_early"), id: 3 },
        { name: this.$t("lang.querytype_late"), id: 4 },
        { name: this.$t("lang.querytype_short"), id: 5 },
        { name: this.$t("lang.querytype_long"), id: 6 },
      ],
      lstTotalType: [
        { name: this.$t("lang.selectedcompany"), id: 1 },
        { name: this.$t("lang.selecteddepartment"), id: 2 },
        { name: this.$t("lang.selectedrouter"), id: 3 },
        { name: this.$t("lang.selectedplace"), id: 4 },
        { name: this.$t("lang.selectedgroup"), id: 5 },
        { name: this.$t("lang.selectedinspector"), id: 6 },
      ],

      lstData: [],
      lstDataFilter: [],

      showAllCell: true,

      currField: "",
      showDate: false,

      currentDate: new Date(),
      mDate1: "",
      mDate2: "",

      showCalendar: false,
      minDate: new Date(new Date().getFullYear() - 2, 1, 1),
      maxDate: new Date(),

      showAddr: true,
      showRecord: false,
      showRecord_miss: false,
      showRecord_local: false,
      showState: false,
      showTotalType: false,
      showSearch: false,

      showGroup: false,
      showUser: false,
      showDevice:false,
      showKeyword: false,
      showOrder: false,

      mCompany: this.$t("lang.pleaseselect"),
      mCompany1: "-1",
      mDept: this.$t("lang.alldepartment"),
      mDept1: "",
      mLine: this.$t("lang.allrouter"),
      mLine1: "",
      mAddr: this.$t("lang.allplace"),
      mAddr1: "",
      mGroup: this.$t("lang.allgroup"),
      mGroup1: "",
      mUser: this.$t("lang.alluser"),
      mUser1: "",
      mDevice: this.$t("lang.device"),
      mDevice1: "",
      mRecord: this.$t("lang.all"),
      mRecord1: "-1",

      mRecord_local: "1",
      fRecord_local: "1",
      mRecord_miss: "0",
      fRecord_miss: "0",
      mState: "-1",
      fState: "-1",
      mOrder: "desc",
      fOrder: "desc",

      showRepairType: false,
      mRepairType: "-1",
      fRepairType: "-1",

      mTotalType: this.$t("lang.selectedplace"),
      mTotalType1: "4",

      mKeyword: "",
      mKeyword1: "",
    };
  },
  mounted() {
    this.title = this.$route.params.title;
    //console.log(this.title);
    this.form = this.$route.params.f;

    let objData = this.$store.getters;
    this.lstCompany = objData.getCompanys;
    this.lstDept = objData.getDepts;
    this.lstLine = objData.getRoutes;
    this.lstAddr = objData.getPlace;
    this.lstGroup = objData.getGroup;
    this.lstUser = objData.getInspector;
    this.lstDevice=objData.getDevices;

    let mPer = objData.getPermission;
    if (typeof mPer.isAdmin != "undefined" && mPer.isAdmin)
      this.showExport =
        this.form == "PollingQuery" ||
        this.form == "PollingPlan" ||
        this.form == "Pass";
    else this.showExport = false;

    this.showAddr =
      this.form != "Map" &&
      this.form != "CurrLocal" &
      this.form != "TempPatrolQuery" &&
      this.form != "RepairIndex"; //显示地点

    this.showGroup =
      this.form != "ItemQuery" &&
      this.form != "TempPatrolQuery" &&
      this.form != "RepairIndex"; //显示 班组

    this.showUser =
      this.form != "ItemQuery" &&
      this.form != "TempPatrolQuery" &&
      this.form != "RepairIndex"; //显示 人员

    this.showDevice=this.form=="CurrLocal";
    this.showKeyword = this.form == "ItemQuery"; //显示 关键词
    this.showState = this.form == "ItemQuery" || this.form == "Unusual"; //显示 记录状态
    this.showRecord = this.form == "PollingPlan"; //显示 记录类型
    this.showRecord_miss = this.form == "MissRecordAudit"; //显示 漏检审核
    this.showRecord_local = this.form == "CurrLocal"; //显示 人员/设备
    this.showTotalType = this.form == "Pass"; //显示 统计类型
    this.showOrder = this.form == "PollingQuery" || this.form == "PollingPlan"; //巡检查询，巡检计划查询
    this.showRepairType = this.form == "RepairIndex"; //显示报修记录类型

    this.mDate1 = getSessionStr("Query_Date_1");
    this.mDate2 = getSessionStr("Query_Date_2");
    if (this.mDate1 == "" || this.mDate2 == "") {
      this.mDate1 = this.formatDate(new Date()) + " 00:00:00";
      this.mDate2 = this.formatDate(new Date()) + " 23:59:59";
    }
    this.mCompany = getSessionStr("Query_Company");
    this.mCompany1 = getSessionStr("Query_Company_id");
    if (this.mCompany == "") {
      if (this.lstCompany.length > 0) {
        this.mCompany = this.lstCompany[0].name;
        this.mCompany1 = this.lstCompany[0].id;
      }
    }

    this.mDept = getSessionStr("Query_Dept");
    this.mDept1 = getSessionStr("Query_Dept_id");
    this.mLine = getSessionStr("Query_Line");
    this.mLine1 = getSessionStr("Query_Line_id");
    this.mAddr = getSessionStr("Query_Addr");
    this.mAddr1 = getSessionStr("Query_Addr_id");
    this.mGroup = getSessionStr("Query_Group");
    this.mGroup1 = getSessionStr("Query_Group_id");
    this.mUser = getSessionStr("Query_User");
    this.mUser1 = getSessionStr("Query_User_id");
    this.mDevice = getSessionStr("Query_Device");
    this.mDevice1 = getSessionStr("Query_Device_id"); 
    this.mRecord = getSessionStr("Query_Record");

    this.mRecord1 = getSessionStr("Query_Record_id");
    if( this.mRecord1==undefined|| this.mRecord1==""){
      this.mRecord=this.$t("lang.all");
      this.mRecord1="-1";
    }

    this.mTotalType = getSessionStr("Query_TotalType");
    this.mTotalType1 = getSessionStr("Query_TotalType_id");

    this.mKeyword = getSessionStr("Query_Keyword");

    this.fRecord_local = getSessionStr("Query_Record_local");
    this.mRecord_local = this.fRecord_local;
    if (
      this.fRecord_local == undefined ||
      this.mRecord_local == undefined ||
      this.fRecord_local == "" ||
      this.mRecord_local == ""
    ) {
      this.fRecord_local = "1";
      this.mRecord_local = "1";
    }

    this.fRecord_miss = getSessionStr("Query_Record_miss");
    this.mRecord_miss = this.fRecord_miss;
    if (
      this.fRecord_miss == undefined ||
      this.mRecord_miss == undefined ||
      this.fRecord_miss == "" ||
      this.mRecord_miss == ""
    ) {
      this.fRecord_miss = "0";
      this.mRecord_miss = "0";
    }

    this.fState = getSessionStr("Query_State");
    this.mState = this.fState;
    if (
      this.fState == undefined ||
      this.mState == undefined ||
      this.fState == "" ||
      this.mState == ""
    ) {
      this.fState = "-1";
      this.mState = "-1";
    }

    this.fOrder = getSessionStr("Query_Order");
    this.mOrder = this.fOrder;
    if (
      this.fOrder == undefined ||
      this.mOrder == undefined ||
      this.fOrder == "" ||
      this.mOrder == ""
    ) {
      this.fOrder = "desc";
      this.mOrder = "desc";
    }

    this.fRepairType = getSessionStr("Query_RepairType");
    this.mRepairType = this.fRepairType;
    if (
      this.fRepairType == undefined ||
      this.mRepairType == undefined ||
      this.fRepairType == "" ||
      this.mRepairType == ""
    ) {
      this.fRepairType = "-1";
      this.mRepairType = "-1";
    }
    
  },

  methods: {
    //显示列表，并填充数据
    onShowSearch(f) {
      this.currField = f;
      if (f == "company") {
        this.lstData = this.lstCompany;
      } else if (f == "dept") {
        if (this.mCompany1 != "-1" && this.mCompany1 != "")
          this.lstData = this.lstDept.filter((d) => {
            return d.companyId == this.mCompany1;
          });
        else this.lstData = this.lstDept;
      } else if (f == "line") {
        if (this.mDept1 != "-1" && this.mDept1 != "")
          this.lstData = this.lstLine.filter((d) => {
            return d.deptId == this.mDept1;
          });
        else this.lstData = this.lstLine;
      } else if (f == "addr") {
        if (this.mLine1 != "-1" && this.mLine1 != "")
          this.lstData = this.lstAddr.filter((d) => {
            return d.routeId == this.mLine1;
          });
        else this.lstData = this.lstAddr;
      } else if (f == "group") {
        if (this.mDept1 != "-1" && this.mDept1 != "")
          this.lstData = this.lstGroup.filter((d) => {
            return d.deptId == this.mDept1;
          });
        else this.lstData = this.lstGroup;
      } else if (f == "user") {
        if (this.mGroup1 != "-1" && this.mGroup1 != "")
          this.lstData = this.lstUser.filter((d) => {
            return d.groupId != undefined && d.groupId == this.mGroup1;
          });
        else if (this.mDept1 != "-1" && this.mDept1 != "")
          this.lstData = this.lstUser.filter((d) => {
            return d.deptId != undefined && d.deptId == this.mDept1;
          });
        else this.lstData = this.lstUser;
      } else if (f == "device") {
        this.lstData = this.lstDevice;
      } else if (f == "record") {
        this.lstData = this.lstRecord;
      } else if (f == "totaltype") {
        this.lstData = this.lstTotalType;
      }
      this.showAllCell = f != "totaltype" && f != "company"; //不显示所有
      this.lstDataFilter = this.lstData;
      this.showSearch = true;
    },

    formatter(type, val) {
      if (type === "year") {
        return val + this.$t("lang.year");
      }
      if (type === "month") {
        return val + this.$t("lang.month");
      }
      if (type === "day") {
        return val + this.$t("lang.day");
      }
      if (type === "hour") {
        return val + this.$t("lang.hour");
      }
      if (type === "minute") {
        return val + this.$t("lang.minute");
      }
      return val;
    },

    //列表选中值
    onSelected(id, name) {
      if (this.currField == "company") {
        if (id == "-1") {
          this.$toast($t("lang.selectcompany"));
          return;
        } else if (this.mCompany1 != id) {
          this.SetFieldValue(this.currField, name, id);
          this.SetFieldValue("dept", this.$t("lang.all"), "-1");
          this.SetFieldValue("line", this.$t("lang.all"), "-1");
          this.SetFieldValue("addr", this.$t("lang.all"), "-1");
          this.SetFieldValue("group", this.$t("lang.all"), "-1");
          this.SetFieldValue("user", this.$t("lang.all"), "-1");
        }
      } else if (this.currField == "dept" && this.mDept1 != id) {
        this.SetFieldValue(this.currField, name, id);
        this.SetFieldValue("line", this.$t("lang.all"), "-1");
        this.SetFieldValue("addr", this.$t("lang.all"), "-1");
        this.SetFieldValue("group", this.$t("lang.all"), "-1");
        this.SetFieldValue("user", this.$t("lang.all"), "-1");
      } else if (this.currField == "line" && this.mLine1 != id) {
        this.SetFieldValue(this.currField, name, id);
        this.SetFieldValue("addr", this.$t("lang.all"), "-1");
      } else if (this.currField == "addr" && this.mAddr1 != id) {
        this.SetFieldValue(this.currField, name, id);
      } else if (this.currField == "group" && this.mGroup1 != id) {
        this.SetFieldValue(this.currField, name, id);
        this.SetFieldValue("user", this.$t("lang.all"), "-1");
      } else if (this.currField == "user" && this.mUser1 != id) {
        this.SetFieldValue(this.currField, name, id);
      } else if (this.currField == "device" && this.mDevice1 != id) {
        this.SetFieldValue(this.currField, name, id);
      } else if (this.currField == "record" && this.mRecord1 != id) {
        this.SetFieldValue(this.currField, name, id);
      } else if (this.currField == "totaltype" && this.mTotalType1 != id) {
        this.SetFieldValue(this.currField, name, id);
      }
      this.showSearch = false;
    },

    SetFieldValue(f, name, id) {
      if (f == "company") {
        this.mCompany = name;
        this.mCompany1 = id;
        setSessionStr("Query_Company", name);
        setSessionStr("Query_Company_id", id);
      } else if (f == "dept") {
        this.mDept = name;
        this.mDept1 = id;
        setSessionStr("Query_Dept", name);
        setSessionStr("Query_Dept_id", id);
      } else if (f == "line") {
        this.mLine = name;
        this.mLine1 = id;
        setSessionStr("Query_Line", name);
        setSessionStr("Query_Line_id", id);
      } else if (f == "addr") {
        this.mAddr = name;
        this.mAddr1 = id;
        setSessionStr("Query_Addr", name);
        setSessionStr("Query_Addr_id", id);
      } else if (f == "group") {
        this.mGroup = name;
        this.mGroup1 = id;
        setSessionStr("Query_Group", name);
        setSessionStr("Query_Group_id", id);
      } else if (f == "user") {
        this.mUser = name;
        this.mUser1 = id;
        setSessionStr("Query_User", name);
        setSessionStr("Query_User_id", id);
      } else if (f == "record") {
        this.mRecord = name;
        this.mRecord1 = id;
        setSessionStr("Query_Record", name);
        setSessionStr("Query_Record_id", id);
      } else if (f == "device") {
        this.mDevice = name;
        this.mDevice1 = id;
        setSessionStr("Query_Device", name);
        setSessionStr("Query_Device_id", id);
      } else if (f == "totaltype") {
        this.mTotalType = name;
        this.mTotalType1 = id;
        setSessionStr("Query_TotalType", name);
        setSessionStr("Query_TotalType_id", id);
      }
    },

    //搜索
    onSearch(val) {
      if (this.lstData) {
        this.lstDataFilter = this.lstData.filter((d) => {
          return d.name.indexOf(val) != -1;
        });
      }
    },

    //日历选择中了日期区间
    onConfirmTime() {
      if (this.currField == "fDate1") {
        this.mDate1 = this.$Tools.FormatDate1(this.currentDate);
        setSessionStr("Query_Date_1", this.mDate1);
      } else if (this.currField == "fDate2") {
        let t = this.$Tools.FormatDate1(this.currentDate);
        this.mDate2 = t.replace(/(.*)00/, "$159");
        setSessionStr("Query_Date_2", this.mDate2);
      }
      this.showDate = false;
      this.currField = "";
    },

    //确认查询条件
    onSubmit(f) {
      if (this.mCompany1 == "-1") {
        this.$toast(this.$t("lang.selectcompany"));
        return;
      }

      //检查时间有效性
      let a1 = Date.parse(new Date(this.mDate1));
      let a2 = Date.parse(new Date(this.mDate2));
      let days = parseInt((a2 - a1) / (1000 * 60 * 60 * 24)); //核心：时间戳相减，然后除以天数
      if (a2 < a1) {
        this.$toast(this.$t("lang.checktime_hint1"));
        return;
      }
      //if (days > 40) {
      //  this.$toast(this.$t("lang.checktime_hint2"));
      //  return;
      //}

      let par = {
        mCompany: this.mCompany1 == "-1" ? "" : this.mCompany1,
        mDept: this.mDept1 == "-1" ? "" : this.mDept1,
        mDate1: this.mDate1,
        mDate2: this.mDate2,
        mLine: this.mLine1 == "-1" ? "" : this.mLine1,
        mAddr: this.mAddr1 == "-1" ? "" : this.mAddr1,
        mGroup: this.mGroup1 == "-1" ? "" : this.mGroup1,
        mUser: this.mUser1 == "-1" ? "" : this.mUser1,
        mDevice: this.mDevice1 == "-1" ? "" : this.mDevice1,
        mRecord: this.mRecord1 == "-1" ? "" : this.mRecord1,
        mRecord_miss: f.fRecord_miss,
        mRecord_local: f.fRecord_local,
        mState: f.fState == "-1" ? "" : f.fState,
        mTotalType: this.mTotalType1 == "-1" ? "4" : this.mTotalType1,
        mKeyword: this.mKeyword,
        mOrder: f.fOrder,
        mRepairType: f.fRepairType,
        pagesize: 20,
        event: this.bt_event,
      };

      setSessionStr("Query_Record_miss", f.fRecord_miss);
      setSessionStr("Query_Record_local", f.fRecord_local);
      setSessionStr("Query_State", f.fState);
      setSessionStr("Query_Order", f.fOrder);
      setSessionStr("Query_RepairType", f.fRepairType);
      setSessionStr("Query_Keyword", f.fKeyword);
      setSessionObj("Query_Params", par);
      this.$router.replace({ name: this.form, params: { p: par } });
    },
  },
};
</script>
